import "./dashboard.scss";
import New from "../new/New";
import OrderCard from "../orderCard/OrderCard";
import WorkdayInfo from "../workdayInfo/WorkdayInfo";
import { resetCart } from "../../redux/cartReducer";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";


import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";
import PersonIcon from "@mui/icons-material/Person";
import { useContext } from "react";
import { DriverContext } from "../../context/driverContext";
import { CashierContext } from "../../context/cashierContext";
import { AuthContext } from "../../context/AuthContext";
import WorkedHours from "../workedHours/WorkedHours";


const Dashboard = () => {
  const [openNew, setOpenNew] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [list, setList] = useState();
  const [listMode, setListMode] = useState(false);
  const [pendingList, setPendingList] = useState();
  const [preparationList, setPreparationList] = useState();
  const [readyList, setReadyList] = useState();
  const [deliveredList, setDeliveredList] = useState();
  const [cancelledList, setCancelledList] = useState();
  const [data, setData] = useState();
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const dispatch = useDispatch();
  const { dispatchDriver, selectedDriver } = useContext(DriverContext);
  const { dispatchCashier, selectedCashier } = useContext(CashierContext);
const { dispatch: dispatchLogout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openHours, setOpenHours] = useState(false);


  const [pin, setPin] = useState("");
  const [isPinCorrect, setIsPinCorrect] = useState(false);

  const dayTotal = list?.filter(({ status }) => status !== "cancelled").reduce(
    (total, currentValue) => (total = total + currentValue.totalPrice),
    0
  );

  const quantityTotal = list
    ?.filter(({ status }) => status !== "cancelled")
    .reduce(
      (total, currentValue) =>
        (total =
          total +
          currentValue.products?.reduce(
            (total2, currentValue2) =>
              (total2 = total2 + currentValue2.quantity),
            0
          )),
      0
    );

  // const listPizza = list
  //   ?.filter(({ status }) => status !== "cancelled")
  //   .map((order) =>
  //     order.products
  //       .filter(({ category }) => category === "pizza")
  //       .reduce((sum, record) => sum + record.quantity, 0)
  //   );

  // const listBurgery = list?.map((order) =>
  //   order.products
  //     .filter(({ category }) => category === "burgery")
  //     .reduce((sum, record) => sum + record.quantity, 0)
  // );

  // const listZapiekanki = list?.map((order) =>
  //   order.products
  //     .filter(({ category }) => category === "zapiekanki")
  //     .reduce((sum, record) => sum + record.quantity, 0)
  // );

  // const listSalatki = list?.map((order) =>
  //   order.products
  //     .filter(({ category }) => category === "sałatki")
  //     .reduce((sum, record) => sum + record.quantity, 0)
  // );

  const listDodatki = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(({ category }) => category === "dodatki")
        .reduce((sum, record) => sum + record.quantity, 0)
    );

 const list2Pizza = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            (name === "DWIE ŚREDNIE" || name === "DWIE DUŻE")
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );

  const list3Pizza = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            (name === "3x ⌀30cm Pizza" || name === "3x ⌀40cm Pizza")
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );

  const list2Burger = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            (name === "2xBURGER+2xFRYTKI")
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );
    const list2Zapiekanka = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            (name === "2xZAPIEKANKA+2xFRYTKI")
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );

  const list3Burger = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" && name === "3x Burger"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );
  const list3Zapiekanka = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" && name === "3x Zapiekanka"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );

  const list4Zapiekanka = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            name === "4x Zapiekanka + 2x Frytki + 20x Nuggetsy"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );
  const list4Burger = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            name === "4x Burger + 2x Frytki + 20x Nuggetsy"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );
  const list4Pizza = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            name === "4x ⌀30cm Pizza + 6x Frytki + 3x Sos"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );
  const list2Pizza2Burger = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            name === "2x ⌀40cm Pizza + 2x Burger + 3x Frytki + 20x Nuggetsy"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );
  const list2Pizza2Zapiekanka = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(
          ({ name, category }) =>
            category === "promocje" &&
            name ===
              "2x ⌀40cm Pizza + 2x Zapiekanka + 4x Frytki + 10x Nuggetsy + 3x Sos"
        )
        .reduce((sum, record) => sum + record.quantity, 0)
    );

  const listPromocje = list
    ?.filter(({ status }) => status !== "cancelled")
    .map((order) =>
      order.products
        .filter(({ category }) => category === "promocje")
        .reduce((sum, record) => sum + record.quantity, 0)
    );


  const gotowka = list
    ?.filter(
      ({ paymentMethod, status }) =>
        paymentMethod === "cash" && status !== "cancelled"
    )
    .reduce((sum, record) => sum + record.totalPrice, 0);

  const terminal = list
    ?.filter(
      ({ paymentMethod, status }) =>
        paymentMethod === "terminal" && status !== "cancelled"
    )
    .reduce((sum, record) => sum + record.totalPrice, 0);

  const online = list
    ?.filter(
      ({ paymentMethod, status }) =>
        paymentMethod === "online" && status !== "cancelled"
    )
    .reduce((sum, record) => sum + record.totalPrice, 0);
 const pyszne = list
    ?.filter(
      ({ paymentMethod, status }) =>
        paymentMethod === "pyszne" && status !== "cancelled"
    )
    .reduce((sum, record) => sum + record.totalPrice, 0);

  // const pizzaTotal = listPizza?.reduce((sum, a) => sum + a, 0);
  // const burgeryTotal = listBurgery?.reduce((sum, a) => sum + a, 0);
  // const zapiekankiTotal = listZapiekanki?.reduce((sum, a) => sum + a, 0);
  // const salatkiTotal = listSalatki?.reduce((sum, a) => sum + a, 0);
  const dodatkiTotal = listDodatki?.reduce((sum, a) => sum + a, 0);
  const pizza2 = list2Pizza?.reduce((sum, a) => sum + a, 0);

const pizza3 = list3Pizza?.reduce((sum, a) => sum + a, 0);
    const pizza4 = list4Pizza?.reduce((sum, a) => sum + a, 0);
    const burger2 = list2Burger?.reduce((sum, a) => sum + a, 0);
   
 const burger3 = list3Burger?.reduce((sum, a) => sum + a, 0);
    const burger4 = list4Burger?.reduce((sum, a) => sum + a, 0);
    const zapiekanka2 = list2Zapiekanka?.reduce((sum, a) => sum + a, 0);

    const zapiekanka3 = list3Zapiekanka?.reduce((sum, a) => sum + a, 0);
    const zapiekanka4 = list4Zapiekanka?.reduce((sum, a) => sum + a, 0);
    const pizza2burger2 = list2Pizza2Burger?.reduce((sum, a) => sum + a, 0);
    const pizza2zapiekanka2 = list2Pizza2Zapiekanka?.reduce(
      (sum, a) => sum + a,
      0
    );
    const promocjeTotal = listPromocje?.reduce((sum, a) => sum + a, 0);


  // przerobić przy użyciu useFetch? wykorzystać reFetch

  const handleDate = (e) => {
    setDate(e.target.value);
  };

const fetchData = async () => {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    console.log("feczuje");
    const response = await fetch(`/api/orders/day/${day}/${month}/${year}`);
    const dataRes = await fetch(`/api/employees`);
    if (dataRes.status === 403) {
      dispatchLogout({ type: "LOGOUT" });
      navigate(`/login`);
    }
    const dataEmployee = await dataRes.json();
    setData(dataEmployee);
    const json = await response.json();
    setList(json);
    // console.log(json);
    const responsePending = await fetch(
      `/api/orders/today/${day}/${month}/${year}/${"pending"}`
    );
    const jsonPending = await responsePending.json();
    setPendingList(jsonPending);
    const responsePreparation = await fetch(
      `/api/orders/today/${day}/${month}/${year}/${"preparation"}`
    );
    const jsonPreparation = await responsePreparation.json();
    setPreparationList(jsonPreparation);
    const responseReady = await fetch(
      `/api/orders/today/${day}/${month}/${year}/${"ready"}`
    );
    const jsonReady = await responseReady.json();
    setReadyList(jsonReady);
    const responseDelivered = await fetch(
      `/api/orders/today/${day}/${month}/${year}/${"delivered"}`
    );
    const jsonDelivered = await responseDelivered.json();
    setDeliveredList(jsonDelivered);
    const responseCancelled = await fetch(
      `/api/orders/today/${day}/${month}/${year}/${"cancelled"}`
    );
    const jsonCancelled = await responseCancelled.json();
    setCancelledList(jsonCancelled);
  };


  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [date]);
 
  const handlePinChange = (e) =>{
    setPin(e.target.value);
  }

  const handlePinCorrect = () =>{
    if(pin === process.env.REACT_APP_PIN){
      setIsPinCorrect(true);
    } else {
      setIsPinCorrect(false);
    }
  }

const handleDriver = (e) => {
    dispatchDriver({ type: "SELECT_DRIVER", selectedDriver: e.target.value });
  };

  const handleCashier = (e) => {
    dispatchCashier({
      type: "SELECT_CASHIER",
      selectedCashier: e.target.value,
    });
  };


  return (
    <>
      <div className="dashboard">
        <div className="dashboardTop">
          <div className="dashboardData">
<div className="pin">
              <input className="pinInput" type="password" placeholder="Wprowadź hasło" value={pin} onChange={handlePinChange} />
              <button className="pinBtn" onClick={handlePinCorrect}>ENTER</button></div>
            {isPinCorrect && <div className="datePicker">
              <input type="date" value={date} onChange={handleDate} />
            </div>}

<div className="chooseEmployees">
              <div className="formInput">
                <label htmlFor="paymentReciver">Kierownik zmiany:</label>
                <select
                  id="paymentReciver"
                  onChange={handleCashier}
                  defaultValue={selectedCashier}
                >
                  <option value="wybierz pracownika">Wybierz pracownika</option>
                  {data?.map((employee) => (
                    <option key={employee._id} value={employee._id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <label htmlFor="paymentReciver">Kierowca dnia:</label>
                <select
                  id="paymentReciver"
                  onChange={handleDriver}
                  defaultValue={selectedDriver}
                >
                  <option value="wybierz pracownika">Wybierz pracownika</option>
                  {data?.map((employee) => (
                    <option key={employee._id} value={employee._id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
<div className="workedHours">
              <button onClick={() => setOpenHours(true)}>
                DODAJ GODZINY PRACY
              </button>
            </div>

            <div className="dashboardDataTop">
              <WorkdayInfo
                title={"Łączny utarg"}
                details={dayTotal?.toFixed(2) + "zł"}
                icon={<CurrencyExchangeIcon className="icon" />}
              />
              <WorkdayInfo
                title={"Łącznie gotówka"}
                details={gotowka?.toFixed(2) + "zł"}
                icon={<AttachMoneyIcon className="icon" />}
              />
              <WorkdayInfo
                title={"Łącznie karta"}
                details={terminal?.toFixed(2) + "zł"}
                icon={<CreditScoreIcon className="icon" />}
              />
              <WorkdayInfo
                title={"Łącznie online"}
                details={online?.toFixed(2) + "zł"}
                icon={<PriceCheckIcon className="icon" />}
              />
<WorkdayInfo
                title={"Łącznie pyszne"}
                details={pyszne?.toFixed(2) + "zł"}
                icon={<PriceCheckIcon className="icon" />}
              />
              <WorkdayInfo
                title={"Liczba sztuk"}
                details={quantityTotal - dodatkiTotal - 
promocjeTotal +
                  2* pizza2 +
                  3 * pizza3 +
                  4 * pizza4 +
                  2 * burger2 +
                  3 * burger3 +
                  4 * burger4 +
                  2 * zapiekanka2 +
                  3 * zapiekanka3 +
                  4 * zapiekanka4 +
                  4 * pizza2burger2 +
                  4 * pizza2zapiekanka2
}
                icon={<LocalPizzaIcon className="icon" />}
              />
            </div>

            <div className="dashboardDataBottom">
              {data?.map((employee) => {
                const employeeSum = list
                  ?.filter(
                    ({ paymentReciver, paymentMethod, status }) =>
                      paymentReciver === employee._id &&
                      paymentMethod === "cash" && status !== "cancelled"
                  )
                  .reduce((sum, record) => sum + record.totalPrice, 0);
                return (
                  employeeSum > 0 && (
                    <WorkdayInfo
                      key={employee._id}
                      title={employee.firstName + " " + employee.lastName}
                      details={employeeSum.toFixed(2) + "zł"}
                      icon={<PersonIcon className="icon" />}
                    />
                  )
                );
              })}
            </div>
            <div className="showProducts">
              <button onClick={() => setShowProducts(!showProducts)}>
                {showProducts ? "UKRYJ PRODUKTY" : "POKAŻ PRODUKTY"}
              </button>
<button onClick={() => setListMode(!listMode)}>
                {listMode ? "WIDOK KAFELKA" : "WIDOK LISTY"}
              </button>
            </div>
          </div>

          <button
            className="addOrderButton"
            onClick={() => {
              dispatch(resetCart());
              setOpenNew(true);
            }}
          >
            DODAJ ZAMÓWIENIE
          </button>
        </div>
        <div className="dashboardBottom">
          {pendingList?.length > 0 && (
            <div className="activeOrders">
              <div className="title">Czekające na potwierdzenie:</div>
              {listMode ? 
              <div className="listMode">
              {pendingList?.map((order, index) => (
                <OrderCard
                  order={order}
                  key={order._id}
                  index={index}
                  showProducts={showProducts}
                  listMode={listMode}
                      fetch={fetchData}

                />
              ))}
            </div>
              : <div className="list">
                {pendingList?.map((order, index) => (
                  <OrderCard
                    order={order}
                    key={order._id}
                    index={index}
                    showProducts={showProducts}
                    listMode={listMode}
                      fetch={fetchData}

                  />
                ))}
              </div>}
            </div>
          )}
          {preparationList?.length > 0 && (
            <div className="activeOrders">
              <div className="title">Kuchnia:</div>
              {listMode ? 
              <div className="listMode">
              {preparationList?.map((order, index) => (
                <OrderCard
                  order={order}
                  key={order._id}
                  index={index}
                  showProducts={showProducts}
                  listMode={listMode}
                      fetch={fetchData}

                />
              ))}
            </div>
              : <div className="list">
                {preparationList?.map((order, index) => (
                  <OrderCard
                    order={order}
                    key={order._id}
                    index={index}
                    showProducts={showProducts}
                    listMode={listMode}
                      fetch={fetchData}

                  />
                ))}
              </div>}
            </div>
          )}
          {readyList?.length > 0 && (
            <div className="activeOrders">
              <div className="title">Dostawa:</div>
              {listMode ? 
              <div className="listMode">
              {readyList?.map((order, index) => (
                <OrderCard
                  order={order}
                  key={order._id}
                  index={index}
                  showProducts={showProducts}
                  listMode={listMode}
                      fetch={fetchData}

                />
              ))}
            </div>
              : <div className="list">
                {readyList?.map((order, index) => (
                  <OrderCard
                    order={order}
                    key={order._id}
                    index={index}
                    showProducts={showProducts}
                    listMode={listMode}
                      fetch={fetchData}

                  />
                ))}
              </div>}
            </div>
          )}
          {deliveredList?.length > 0 && (
            <div className="activeOrders">
              <div className="title">Dostarczone:</div>
              {listMode ? 
              <div className="listMode">
              {deliveredList?.map((order, index) => (
                <OrderCard
                  order={order}
                  key={order._id}
                  index={index}
                  showProducts={showProducts}
                  listMode={listMode}
                      fetch={fetchData}

                />
              ))}
            </div>
              : <div className="list">
                {deliveredList?.map((order, index) => (
                  <OrderCard
                    order={order}
                    key={order._id}
                    index={index}
                    showProducts={showProducts}
                    listMode={listMode}
                      fetch={fetchData}

                  />
                ))}
              </div>}
            </div>
          )}
          {cancelledList?.length > 0 && (
            <div className="activeOrders">
              <div className="title">Odrzucone:</div>
              {listMode ? 
              <div className="listMode">
              {cancelledList?.map((order, index) => (
                <OrderCard
                  order={order}
                  key={order._id}
                  index={index}
                  showProducts={showProducts}
                  listMode={listMode}
                      fetch={fetchData}

                />
              ))}
            </div>
              : <div className="list">
                {cancelledList?.map((order, index) => (
                  <OrderCard
                    order={order}
                    key={order._id}
                    index={index}
                    showProducts={showProducts}
                    listMode={listMode}
                      fetch={fetchData}

                  />
                ))}
              </div>}
            </div>
          )}
        </div>
      </div>
      {openNew && <New closeNew={setOpenNew} fetch={fetchData}  />}
      {openHours && <WorkedHours data={data} closeHours={setOpenHours} />}
    </>
  );
};

export default Dashboard;

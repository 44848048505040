import React from "react";
import "./navbar.scss";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Navbar = () => {
  const { dispatchMode } = useContext(DarkModeContext);
  const { user } = useContext(AuthContext);
  console.log(user);
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatchMode({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            <div className="details">
              <span className="name">{user.firstName} {user.lastName}</span>
              <span className="position">Administrator</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

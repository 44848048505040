import React from "react";
import "./workedHours.scss";
import { useState } from "react";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useEffect } from "react";

const WorkedHours = ({ closeHours, data }) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [employee, setEmployee] = useState({});
  const [list, setList] = useState();
  const [pin, setPin] = useState("");
  const [isPinCorrect, setIsPinCorrect] = useState(false);

  const handleDate = (e) => {
    console.log(e.target.value);
    setDate(e.target.value);
  };
  const handleStartTime = (e) => {
    setStartTime(e.target.value);
  };
  const handleEndTime = (e) => {
    setEndTime(e.target.value);
  };

  const handleEmployee = (e) => {
    const selectedEmployeeObject = data.find(
      (employee) => employee._id === e.target.value
    );
    setEmployee(selectedEmployeeObject);
  };

  const handleClick = async () => {
    const start = new Date();
    const end = new Date();
    start.setHours(startTime.slice(0, 2));
    start.setMinutes(startTime.slice(3, 5));
    end.setHours(endTime.slice(0, 2));
    end.setMinutes(endTime.slice(3, 5));
    const hours = end.getTime() - start.getTime();
    const timeWorked = Math.round(hours / (1000 * 60));
    const hoursWorked = (timeWorked / 60).toFixed(2);
    const time = new Date();
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    time.setUTCMonth(month);
    time.setUTCDate(day);
    time.setFullYear(year);
    time.setUTCHours(0, 0, 0, 0);

    try {
      const existingWorkHistory = employee.workHistory || [];

      const existingEntryIndex = existingWorkHistory.findIndex(
        (entry) => Date.parse(entry.date) === Date.parse(time)
      );

      console.log(existingEntryIndex);

      if (existingEntryIndex !== -1) {
        existingWorkHistory[existingEntryIndex] = {
          date: date,
          startTime: startTime,
          endTime: endTime,
          hoursWorked: hoursWorked,
        };
      } else {
        existingWorkHistory.push({
          date: date,
          startTime: startTime,
          endTime: endTime,
          hoursWorked: hoursWorked,
        });
      }

      const newEmployee = {
        ...employee,
        workHistory: existingWorkHistory,
      };
      console.log(newEmployee);
      await axiosInstance.put(`/employees/${employee._id}`, newEmployee);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (employee) => {
    const time = new Date();
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    time.setUTCMonth(month);
    time.setUTCDate(day);
    time.setFullYear(year);
    time.setUTCHours(0, 0, 0, 0);

    try {
      const selectedEmployeeObject = data.find((e) => e._id === employee._id);
      console.log(selectedEmployeeObject);
      const filteredWorkHistory = selectedEmployeeObject.workHistory.filter(
        (entry) => Date.parse(entry.date) !== Date.parse(time)
      );
      console.log(filteredWorkHistory);

      const employeeData = {
        ...employee,
        workHistory: filteredWorkHistory,
      };
      await axiosInstance.put(`/employees/${employee._id}`, employeeData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    const fetchData = async () => {
      const response = await fetch(
        `/api/employees/day/${day}/${month}/${year}`
      );
      const json = await response.json();
      setList(json);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 1 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [date]);

  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const handlePinCorrect = () => {
    if (pin === process.env.REACT_APP_PIN) {
      setIsPinCorrect(true);
    } else {
      setIsPinCorrect(false);
    }
  };


  return (
    <div className="workedHoursContainer">
      <div className="workedHoursDetails">
        <div className="workedHoursTop">
          <h1>DODAJ GODZINY PRACY</h1>
          <button className="closeButton" onClick={() => closeHours(false)}>
            &times;
          </button>
        </div>
        <div className="workedHoursCenter">
          <div className="pin">
            <input
              className="pinInput"
              type="password"
              placeholder="Wprowadź hasło"
              value={pin}
              onChange={handlePinChange}
            />
            <button className="pinBtn" onClick={handlePinCorrect}>
              ENTER
            </button>
          </div>
          {isPinCorrect && (
              <input type="date" value={date} onChange={handleDate} />
          )}
          {/* <input type="date" value={date} onChange={handleDate} /> */}
          <div className="workedHoursForm">
            <div className="formInput">
              <select id="employee" onChange={handleEmployee}>
                <option value="wybierz pracownika">Wybierz pracownika</option>
                {data?.map((employee) => (
                  <option key={employee._id} value={employee._id}>
                    {employee.firstName} {employee.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className="formInput">
              START:
              <input type="time" value={startTime} onChange={handleStartTime} />
            </div>
            <div className="formInput">
              KONIEC:
              <input type="time" value={endTime} onChange={handleEndTime} />
            </div>
            <button className="addBtn" onClick={handleClick}>
              DODAJ
            </button>
          </div>
        </div>
        <div className="workedHoursBottom">
          <h3>{date} | LISTA PRACOWNIKÓW W TYM DNIU:</h3>
          {list?.map((employee, index) => (
            <div className="person">
              <PersonIcon className="icon" /> {employee?.firstName}{" "}
              {employee?.lastName} {employee?.workHistory[0].startTime} -{" "}
              {employee?.workHistory[0].endTime}
              <DeleteForeverOutlinedIcon
                className="delete"
                onClick={() => handleDelete(employee)}
              />
              KSIĄŻECZKA WAŻNA DO: {employee?.allowed}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkedHours;

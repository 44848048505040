import "./edit.scss";
import { resetCart } from "../../redux/cartReducer";
import Duo from "../duo/Duo";
import OrderItems from "../orderItems/OrderItems";
import Item from "../item/Item";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { streets } from "../../data/streets";
import { options } from "../../data/cities";
import { categories } from "../../data/categories";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import { useEffect } from "react";


import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";

const Edit = ({ order, closeEditor, fetch }) => {
  const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL, withCredentials: true})

  const [info, setInfo] = useState(order);
  const [delivery, setDelivery] = useState(info.delivery);
  const [tip, setTip] = useState(info.tip);
  const [discount, setDiscount] = useState(info.discount || 0);
  const [openDuo, setOpenDuo] = useState(false);
  const [category, setCategory] = useState("pizza");
  const [customer, setCustomer] = useState(false);
  const { data, loading } = useFetch(`/products/category/${category}`);
  const { data: strefa } = useFetch(`/ingredients/category/dowóz/a`);
  const {
    data: employeeData,
  } = useFetch(`/employees`);

 const { data: voucherData } = useFetch(`/vouchers`);
  const [voucherName, setVoucherName] = useState(info.voucherName);
  const [voucherAmount, setVoucherAmount] = useState(info.discountAmount);
  const [voucherPercentage, setVoucherPercentage] = useState();
  const [voucherError, setVoucherError] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companyDiscount, setCompanyDiscount] = useState(info.discount > 0 ? true : false);
  const [companyDiscountVisible, setCompanyDiscountVisible] = useState(true);

  const products = useSelector((state) => state.cart.products);

  const getTimeRange = (startTime) => {
    const start = new Date(startTime);
    const end = new Date();
    start.setHours(13, 0 ,0);
    end.setHours(22, 0, 0);

    let timeRange = [];
    while (start <= end) {
      let x = start.getMinutes();
      let y = 0;
      while (x > y && x < 60) {
        start.setMinutes(y + 10);
        y = y + 10;
      }
      // start.setMinutes(start.getMinutes() + 5);

      timeRange.push(
        new Date(start).toLocaleTimeString("pl-PL", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
          " - " +
          new Date(
            start.setMinutes(start.getMinutes() + 10)
          ).toLocaleTimeString("pl-PL", {
            hour: "2-digit",
            minute: "2-digit",
          })
      );
    }
    return timeRange;
  };
  let timeRange = getTimeRange(new Date());

  const handleChange = (e) => {
    if (e.target) {
      setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    } else {
      if (e.id) {
        setInfo((prev) => ({ ...prev, [e.id]: e.value }));
        if (e.strefa) {
          setInfo((prev) => ({
            ...prev,
            [e.id]: e.value,
            "strefa": e.strefa,
          }));
        }
      } else {
        setInfo((prev) => ({ ...prev, "street": e.value }));
      }
    }
  };

  // const cartTotal = () => {
  //   let cartTotal = 0;
  //   products.forEach((item) => (cartTotal += item.quantity * item.price));
  //   if (delivery !== "false") {
  //     if (info.strefa === "A") {
  //       if (info.city === "kościan") {
  //         if (
  //           info.street !== "poznańska" &&
  //           info.street !== "osiedle konstytucji 3 maja"
  //         ) {
  //           cartTotal += 2;
  //         }
  //       } else {
  //         cartTotal += 2;
  //       }
  //     }
  //     if (info.strefa === "B") {
  //       cartTotal += 7;
  //     }
  //     if (info.strefa === "C") {
  //       cartTotal += 14;
  //     }
  //   }
  //   return cartTotal.toFixed(2);
  // };

  const deliveryTotal = () =>{
    let deliveryCost = info.deliveryCost;
    if (delivery !== "false") {
      if (info.strefa === "A") {
        if (
          info.city === "kościan" &&
          (info.street === "poznańska" ||
            info.street === "szkolna" ||
            info.street === "czempińska" ||
            info.street === "czereśniowa" ||
            info.street === "wiśniowa" ||
            info.street === "górna" ||
            info.street === "pszenna" ||
            info.street === "polna" ||
            info.street === "północna" ||
            info.street === "krańcowa" ||
            info.street === "zielona" ||
            info.street === "osiedle konstytucji 3 maja")
        ) {
          deliveryCost = strefa[1].price[0];
        } else {
          deliveryCost = strefa[2].price[0];
        }
      }
      if (info.strefa === "B") {
        deliveryCost = strefa[3].price[0];
      }
      if (info.strefa === "C") {
        deliveryCost = strefa[4].price[0];
      }
    }
    return deliveryCost.toFixed(2);
  }
  
  const cartTotal = () => {
    let cartTotal = 0;
    products.forEach((item) => (cartTotal += item.quantity * item.price));
    // if (delivery !== "false") {
    //   if (info.strefa === "A") {
    //     if (info.city === "kościan") {
    //       if (
    //         info.street !== "poznańska" &&
    //         info.street !== "osiedle konstytucji 3 maja"
    //       ) {
    //         cartTotal += 2;
    //       }
    //     } else {
    //       cartTotal += 2;
    //     }
    //   }
    //   if (info.strefa === "B") {
    //     cartTotal += 7;
    //   }
    //   if (info.strefa === "C") {
    //     cartTotal += 14;
    //   }
    // }

    // if(tip>0){
    //   cartTotal = cartTotal + (cartTotal*tip);
    // }
    return cartTotal.toFixed(2);
  };

 const supTotal = () => {
    let supTotal = 0;
    products.forEach((item) => (supTotal += item.quantity * item.sup));
    return supTotal.toFixed(2);
  }

  let deliveryCost = deliveryTotal();
  let cartAmount = cartTotal();
  let supAmount = supTotal();
  let tipAmount = (
    (parseFloat(cartAmount) + parseFloat(deliveryCost)) *
    tip
  ).toFixed(2);
 let discountAmount = (
    (parseFloat(cartAmount) + parseFloat(deliveryCost)) *
    discount
  ).toFixed(2);
 if (voucherPercentage > 0) {
    discountAmount = (
      (parseFloat(cartAmount) + parseFloat(deliveryCost)) *
      voucherPercentage *
      0.01
    ).toFixed(2);
  }
  if (voucherAmount > 0) {
    discountAmount = voucherAmount;
  }
  let totalPrice = (parseFloat(cartAmount) + parseFloat(supAmount) + parseFloat(tipAmount) + parseFloat(deliveryCost) - parseFloat(discountAmount)).toFixed(2);


 useEffect(() => {
    let total = parseFloat(cartAmount) + parseFloat(deliveryCost);
    if (companyDiscount) {
      if (total > 100) {
        setDiscount(0.1);
      }
      if (total > 200) {
        setDiscount(0.15);
      }
      if (total > 300) {
        setDiscount(0.2);
      }
    }
    if (companyDiscount === false || total < 100) {
      setDiscount(0);
    }

    // if (new Date().getHours() >= 13 && new Date().getHours() <= 16) {
    //   setCompanyDiscountVisible(true);
    // } else {
    //   setCompanyDiscountVisible(false);
    //   setCompanyDiscount(false);
    // }
  }, [companyDiscount, totalPrice]);


  const handleVoucher = () => {
    console.log(voucherName);
    const voucherDetails = voucherData.find(
      (voucher) => voucher.name === voucherName
    );
    console.log(voucherDetails);
    if (!voucherDetails) {
      setVoucherAmount(0);
      setVoucherPercentage(0);
      setVoucherError("Błędny kod");
    } else {
      setVoucherError("");
      if (voucherDetails.isPercentage) {
        setVoucherPercentage(voucherDetails.value);
        setVoucherAmount(0);
      } else {
        setVoucherAmount(voucherDetails.value);
        setVoucherPercentage(0);
      }
    }
  };
 

 const handleClick = async (e) => {
    e.preventDefault();
    try {
      // let totalPrice = cartTotal();
      if (info.totalPriceNew) {
        totalPrice = info.totalPriceNew;
      }
      const newOrder = {
        ...info,
        products,
        delivery,
        deliveryCost,
        tip,
        tipAmount,
voucherName,
 discount,
        discountAmount,
        totalPrice,
      };
      console.log(newOrder);
      await axiosInstance.put(`/orders/${order._id}`, newOrder);
      navigate("/");
      closeEditor(false);
      dispatch(resetCart());
      fetch();

    } catch (error) {
      console.log(error);
    }
  };

  const handleDelivery = (e) => {
    setDelivery(e.target.value);
    timeRange = getTimeRange(e.target.value, new Date());
    setInfo((prev) => ({ ...prev, deliveryTime: timeRange[0] }));
  };

  return (
    <div className="editOrder">
      <div className="editOrderContainer">
        <div className="top">
          <div className="info">
            <h1>Edytuj zamówienie</h1>
          </div>
          <button className="closeButton" onClick={() => closeEditor(false)}>
            &times;
          </button>
        </div>
        <div className="customerContainer">
          <h1 onClick={() => setCustomer(!customer)}>WPROWADŹ DANE KLIENTA</h1>
        </div>

        {customer && (
          <div className="modalWrapper">
            <div className="customerData">
              <div className="formInput">
                <label htmlFor="phone">Numer telefonu:</label>
                <input
                  onChange={handleChange}
                  type="number"
                  id="phone"
                  defaultValue={info.phone}
                />
              </div>
              <div className="formInput">
                <label htmlFor="customerId">ID klienta:</label>
                <input
                  onChange={handleChange}
                  type="text"
                  id="customerId"
                  defaultValue={info.customerId}
                />
              </div>
            </div>
            <div className="customerData">
              <div className="formInput">
                <label htmlFor="firstName">Imię:</label>
                <input
                  onChange={handleChange}
                  type="text"
                  id="firstName"
                  defaultValue={info.firstName}
                />
              </div>
              <div className="formInput">
                <label htmlFor="lastName">Nazwisko:</label>
                <input
                  onChange={handleChange}
                  type="text"
                  id="lastName"
                  defaultValue={info.lastName}
                />
              </div>
            </div>
            <div className="emailInput">
              <label>Email</label>
              <input
                type="email"
                placeholder="Adres email"
                id="email"
                defaultValue={info.email}
                onChange={handleChange}
              />
            </div>
            <div className="deliveryData">
              <div className="deliveryButtons">
                <button
                  id="delivery"
                  value={true}
                  onClick={handleDelivery}
                  className={
                    delivery !== "false" && delivery !== false
                      ? "deliveryOption active"
                      : "deliveryOption"
                  }
                >
                  Dostawa
                </button>
                <button
                  id="delivery"
                  value={false}
                  onClick={handleDelivery}
                  className={
                    delivery !== "false" && delivery !== false
                      ? "deliveryOption"
                      : "deliveryOption active"
                  }
                >
                  Odbiór osobisty
                </button>
              </div>
              <div className="deliveryDetails">
                {delivery !== "false" && delivery !== false && (
                  <>
                    <Creatable
                      options={streets}
                      onChange={handleChange}
                      defaultInputValue={info.street}
                      placeholder="Ulica"
                    />
                    <div className="homeNumber">
                      <input
                        type="text"
                        placeholder="Numer domu"
                        id="homeNumber"
                        defaultValue={info.homeNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <Select
                      options={options}
                      defaultInputValue={info.city}
                      onChange={handleChange}
                      placeholder="Miasto"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="commentsData">
              <div className="comments">
                <label>Uwagi do zamówienia</label>
                <textarea
                  placeholder="Uwagi do zamówienia"
                  onChange={handleChange}
                  id="comments"
                  defaultValue={info.comments}
                ></textarea>
              </div>
            </div>

<div className="voucherData">
              <div className="vouchers">
                <label htmlFor="voucherName">Voucher:</label>
                <input
                  onChange={(e) => setVoucherName(e.target.value)}
                  type="text"
                  id="voucherName"
                  defaultValue={info.voucherName}
                />
              </div>
              <button onClick={handleVoucher}>ZASTOSUJ</button>
              {voucherError && voucherError}
            </div>

            <div className="tipData">
              <p className="title">Czy chcesz dodać napiwek do zamówienia?</p>
              <div className="tipButtons">
                <button
                  className={tip === 0 ? "tipOption active" : "tipOption"}
                  onClick={() => setTip(0)}
                >
                  Bez napiwku
                </button>
                <button
                  className={tip === 0.05 ? "tipOption active" : "tipOption"}
                  onClick={() => setTip(0.05)}
                >
                  Dodaj 5%
                </button>
                <button
                  className={tip === 0.1 ? "tipOption active" : "tipOption"}
                  onClick={() => setTip(0.1)}
                >
                  Dodaj 10%
                </button>
              </div>
            </div>
{companyDiscountVisible && <div className="tipData">
              <p className="title">Czy chcesz dodać rabat do zamówienia?</p>
              <div className="tipButtons">
                <button
                  className={
                    companyDiscount === true ? "tipOption active" : "tipOption"
                  }
                  onClick={() => setCompanyDiscount(!companyDiscount)}
                >
                  RABAT DLA FIRM
                </button>
              </div>
            </div>}
            <div className="customerData">
              <div className="formInput">
                <label htmlFor="totalPriceNew">Łączna cena:</label>
                <input
                  onChange={handleChange}
                  type="number"
                  id="totalPriceNew"
                  defaultValue={info.totalPrice}
                />
              </div>
              <div className="formInput">
                <label htmlFor="paymentReciver">Pracownik:</label>
                <select
                  id="paymentReciver"
                  onChange={handleChange}
                  defaultValue={info.paymentReciver}
                >
                  <option value="wybierz pracownika">Wybierz pracownika</option>
                  {employeeData.map((employee) => (
                    <option key={employee._id} value={employee._id}>
                      {employee.firstName} {employee.lastName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="customerData">
              <div className="formInput">
                <label>
                  Wybierz czas {delivery !== "false" ? "dostawy" : "odbioru"}:
                </label>
                <select
                  id="deliveryTime"
                  className="select"
                  onChange={handleChange}
                  value={info.deliveryTime}
                >
                  {delivery !== "false" && (
                    <option value="jak najszybciej">Jak najszybciej</option>
                  )}
                  {timeRange.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formInput">
                <label>Wybierz metodę płatności:</label>
                <select
                  id="paymentMethod"
                  className="select"
                  defaultValue={info.paymentMethod}
                  onChange={handleChange}
                >
                  <option value="cash">Gotówka</option>
                  <option value="terminal">Kartą przy odbiorze</option>
                  <option value="online">Płatność online</option>
                  <option value="pyszne">Pyszne</option>

                </select>
              </div>
            </div>
            <div className="checkoutButton">
              <button onClick={handleClick}>Wyślij</button>
            </div>
          </div>
        )}

        <div className="cartItemsContainer">
          {products.length > 0 && (
            <div className="orderedProductsInfo">
              <DeleteForeverOutlinedIcon
                className="cartDelete"
                onClick={() => dispatch(resetCart())}
              />
              <h1>Zamówione produkty:</h1>
            </div>
          )}
          <OrderItems products={products} editable={true} />
          {products.length > 0 && (
            <div className="priceDetails">
              <h1>Łączna kwota: {totalPrice}zł</h1>
              <h1>Koszt dostawy: {deliveryCost}zł</h1>
              <h1>Napiwek: {tipAmount}zł</h1>
              <h1>Rabat: {discountAmount}zł</h1>
            </div>
          )}
        </div>

        <div className="productsContainer">
          <h1>WYBIERZ PRODUKTY</h1>
        </div>
        <div className="categoriesContainer">
          <ul className="categories">
            {categories.map((category) => (
              <div
                className="categoryItem"
                key={category.id}
                onClick={() => setCategory(category.name)}
              >
                <li>
                  <LocalPizzaIcon className="icon" />
                </li>
                <p className="categoryTitle">{category.name}</p>
              </div>
            ))}
          </ul>
        </div>
        <div className="productsWrapper">
          {category === "pizza" && (
            <div className="itemCard">
              <div className="itemContainer" onClick={() => setOpenDuo(true)}>
                <img
                  className="itemImage"
                  src="http://res.cloudinary.com/dqknlkpku/image/upload/v1675902485/upload/yhli9sounakp2iymdmyx.png"
                  alt="duo"
                />
                <div className="details">
                  <p className="title">pół na pół</p>
                </div>
              </div>
            </div>
          )}
          {loading
            ? "ładowanie..."
            : data.map((product) => (
                <Item product={product} key={product._id} />
              ))}
        </div>
      </div>
      {openDuo && <Duo closeDuo={setOpenDuo} />}
    </div>
  );
};

export default Edit;

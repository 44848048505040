import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  selectedCashier: null,
};

export const CashierContext = createContext(INITIAL_STATE);

const CashierReducer = (state, action) => {
  switch (action.type) {
    case "SELECT_CASHIER": {
      return {
        selectedCashier: action.selectedCashier,
      };
    }
    default:
      return state;
  }
};

export const CashierContextProvider = ({ children }) => {
  const [state, dispatchCashier] = useReducer(CashierReducer, INITIAL_STATE);

  return (
    <CashierContext.Provider value={{ selectedCashier: state.selectedCashier, dispatchCashier }}>
      {children}
    </CashierContext.Provider>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { DriverContextProvider } from "./context/driverContext";
import { CashierContextProvider } from "./context/cashierContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={"loading"} persistor={persistor}>
        <AuthContextProvider>
          <DarkModeContextProvider>
            <DriverContextProvider>
              <CashierContextProvider>
                <App />
              </CashierContextProvider>
            </DriverContextProvider>
          </DarkModeContextProvider>
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

import React from "react";
import "./sidebar.scss";

import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useState } from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import GroupIcon from "@mui/icons-material/Group";
import BadgeIcon from "@mui/icons-material/Badge";
import FlakyIcon from "@mui/icons-material/Flaky";
import MenuIcon from "@mui/icons-material/Menu";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AssessmentIcon from '@mui/icons-material/Assessment';


const Sidebar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [pin, setPin] = useState("");
  const [isPinCorrect, setIsPinCorrect] = useState(false);

  const { dispatchMode } = useContext(DarkModeContext);
  const { dispatch } = useContext(AuthContext);

  const handlePinChange = (e) =>{
    setPin(e.target.value);
  }

  const handlePinCorrect = () =>{
    if(pin === process.env.REACT_APP_PIN){
      setIsPinCorrect(true);
    } else {
      setIsPinCorrect(false);
    }
  }

  return (
    <>
      {!openSidebar && (
        <MenuIcon className="menu" onClick={() => setOpenSidebar(true)} />
      )}
      {openSidebar && (
        <div className="sidebar">
          <div className="top">
            <button onClick={() => setOpenSidebar(false)}>x</button>
          </div>
          <hr />
          <div className="center">
            <ul>
              <p className="title">GŁÓWNE</p>
              <Link to="/" style={{ textDecoration: "none" }}>
                <li>
                  <DashboardIcon className="icon" />
                  <span>Dashboard</span>
                </li>
              </Link>
              <Link to="/options" style={{ textDecoration: "none" }}>
                <li>
                  <SettingsApplicationsIcon className="icon" />
                  <span>Opcje</span>
                </li>
              </Link>

              <div className="pin">
              <input className="pinInput" type="password" placeholder="Wprowadź hasło" value={pin} onChange={handlePinChange} />
              <button className="pinBtn" onClick={handlePinCorrect}>ENTER</button></div>

              {isPinCorrect && <><p className="title">ZARZĄDZAJ</p>
              <Link to="/orders" style={{ textDecoration: "none" }}>
                <li>
                  <CreditCardIcon className="icon" />
                  <span>Zamówienia</span>
                </li>
              </Link>
              <Link to="/products" style={{ textDecoration: "none" }}>
                <li>
                  <FastfoodIcon className="icon" />
                  <span>Produkty</span>
                </li>
              </Link>
              <Link to="/employees" style={{ textDecoration: "none" }}>
                <li>
                  <BadgeIcon className="icon" />
                  <span>Pracownicy</span>
                </li>
              </Link>
              <Link to="/users" style={{ textDecoration: "none" }}>
                <li>
                  <GroupIcon className="icon" />
                  <span>Użytkownicy</span>
                </li>
              </Link>
              <Link to="/ingredients" style={{ textDecoration: "none" }}>
                <li>
                  <FlakyIcon className="icon" />
                  <span>Składniki</span>
                </li>
              </Link>
            <Link to="/vouchers" style={{ textDecoration: "none" }}>
                <li>
                  <PriceChangeIcon className="icon" />
                  <span>Vouchery</span>
                </li>
              </Link>
<Link to="/report" style={{ textDecoration: "none" }}>
                <li>
                  <AssessmentIcon className="icon" />
                  <span>Raport</span>
                </li>
              </Link>
</>}
              
              <p className="title">UŻYTKOWNIK</p>
              <li>
                <AccountCircleOutlinedIcon className="icon" />
                <span>Profil</span>
              </li>
              <li>
                <ExitToAppIcon className="icon" />
                <span onClick={() => dispatch({ type: "LOGOUT" })}>
                  Wyloguj
                </span>
              </li>
            </ul>
          </div>
          <div className="bottom">
            <div
              className="colorOption"
              onClick={() => dispatchMode({ type: "LIGHT" })}
            ></div>
            <div
              className="colorOption"
              onClick={() => dispatchMode({ type: "DARK" })}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;

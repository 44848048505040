import "./orderItems.scss";
import { removeItem, quantityChange } from "../../redux/cartReducer";

import { useDispatch } from "react-redux";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";


const OrderItems = ({ products, editable }) => {
  const dispatch = useDispatch();

  const handleSwitchMedium = (e) => {
    switch (e.name) {
      case "nuggetsy":
        return "5 sztuk ";
      case "sosy":
        return "25g ";
      case "napoje":
        return "0,33L ";
      case "frytki":
        return "";
      default:
        return "⌀30cm ";
    }
  };

  const handleSwitchLarge = (e) => {
    switch (e.name) {
      case "nuggetsy":
        return "10 sztuk ";
      case "sosy":
        return "100g ";
      case "napoje":
        return "0,5L ";
      case "frytki":
        return "";
      default:
        return "⌀40cm ";
    }
  };

  const handleAddQuantity = (cartItem) =>{
    console.log(cartItem.quantity);
    const q = cartItem.quantity + 1;
    dispatch(
      quantityChange({
        ...cartItem,
        quantity: q
      })
    )
  }

  const handleSubtractQuantity = (cartItem) => {
    const q = cartItem.quantity - 1;
    dispatch(
      quantityChange({
        ...cartItem,
        quantity: q
      })
    )
  }

  return (
    <div className="orderItems">
      <ul className="cartItems">
        {products?.map((cartItem) => (
          <li
            className="cartItem"
            key={
              cartItem.id +
              cartItem.addedIngredients +
              cartItem.excludedIngredients +
              cartItem.size +
              cartItem.taste +
              cartItem.crust +
              cartItem.name +
              cartItem.img +
              cartItem.price +
              cartItem.quantity
            }
          >
            <div className="itemLeft">
              <img src={cartItem.img} alt="" />
              <div className="details">
                <h1>
                  {(cartItem.category === "pizza" ||
                    cartItem.category === "dodatki") &&
                    (cartItem.size === "xlarge"
                      ? "0,85L "
                      : cartItem.size === "large"
                      ? handleSwitchLarge(cartItem)
                      : handleSwitchMedium(cartItem))}
                  {cartItem.name} {cartItem.quantity>1 && "x"+cartItem.quantity}
                </h1>
                {cartItem.sup > 0 && <h5>opłata SUP: {cartItem.quantity}x {cartItem.sup}zł</h5>}
                <div className="cartDetails">
                  {cartItem.crust.length > 0 && <p>Ciasto: {cartItem.crust}</p>}
                  {cartItem.firstHalf.name && (
                    <>
                      <h5>Pierwsza połowa: {cartItem.firstHalf.name}</h5>
                      {cartItem.firstHalf.addedIngredients.length > 0 && (
                        <p>
                          Dodatki:
                          {cartItem.firstHalf.addedIngredients.join(", ")}
                        </p>
                      )}
                      {cartItem.firstHalf.excludedIngredients.length > 0 && (
                        <p>
                          Minus:
                          {cartItem.firstHalf.excludedIngredients.join(", ")}
                        </p>
                      )}
                    </>
                  )}
                  {cartItem.secondHalf.name && (
                    <>
                      <h5>Druga połowa: {cartItem.secondHalf.name}</h5>
                      {cartItem.secondHalf.addedIngredients2.length > 0 && (
                        <p>
                          Dodatki:
                          {cartItem.secondHalf.addedIngredients2.join(", ")}
                        </p>
                      )}
                      {cartItem.secondHalf.excludedIngredients2.length > 0 && (
                        <p>
                          Minus:
                          {cartItem.secondHalf.excludedIngredients2.join(", ")}
                        </p>
                      )}
                    </>
                  )}
                  {cartItem.addedIngredients.length > 0 && (
                    <p>Dodatki: {cartItem.addedIngredients.join(", ")}</p>
                  )}
                  {cartItem.excludedIngredients.length > 0 && (
                    <p>Minus: {cartItem.excludedIngredients.join(", ")}</p>
                  )}
                  {cartItem.taste.length > 0 && <p>Sos: {cartItem.taste}</p>}
                  {cartItem.drink.length > 0 && <p>Napój: {cartItem.drink}</p>}

  {cartItem.firstItem.name && (
                    <>
                       {cartItem.firstItem.name === "sałatka" ? (
                        (cartItem.firstItem.addedIngredients.length > 0 ||
                          cartItem.firstItem.excludedIngredients.length > 0) && (
                          <h5>Sałatka</h5>
                        )
                      ) : (
                        <h5>Pierwszy produkt: {cartItem.firstItem.name}</h5>
                      )}
                      {cartItem.firstItem.crust.length > 0 && (
                        <p>Ciasto: {cartItem.firstItem.crust}</p>
                      )}

                      {cartItem.firstItem.addedIngredients.length > 0 && (
                        <p>
                          Dodatki:{" "}
                          {cartItem.firstItem.addedIngredients.join(", ")}
                        </p>
                      )}
                      {cartItem.firstItem.excludedIngredients.length > 0 && (
                        <p>
                          Minus:{" "}
                          {cartItem.firstItem.excludedIngredients.join(", ")}
                        </p>
                      )}
                    </>
                  )}
                     {cartItem.secondItem.name && (
                    <>
                      <h5>Drugi produkt: {cartItem.secondItem.name}</h5>
                      {cartItem.secondItem.crust.length > 0 && (
                        <p>Ciasto: {cartItem.secondItem.crust}</p>
                      )}

                      {cartItem.secondItem.addedIngredients.length > 0 && (
                        <p>
                          Dodatki:{" "}
                          {cartItem.secondItem.addedIngredients.join(", ")}
                        </p>
                      )}
                      {cartItem.secondItem.excludedIngredients.length > 0 && (
                        <p>
                          Minus:{" "}
                          {cartItem.secondItem.excludedIngredients.join(", ")}
                        </p>
                      )}
                    </>
                  )}
                     {cartItem.thirdItem.name && (
                    <>
                      <h5>Trzeci produkt: {cartItem.thirdItem.name}</h5>
                      {cartItem.thirdItem.crust.length > 0 && (
                        <p>Ciasto: {cartItem.thirdItem.crust}</p>
                      )}

                      {cartItem.thirdItem.addedIngredients.length > 0 && (
                        <p>
                          Dodatki:{" "}
                          {cartItem.thirdItem.addedIngredients.join(", ")}
                        </p>
                      )}
                      {cartItem.thirdItem.excludedIngredients.length > 0 && (
                        <p>
                          Minus:{" "}
                          {cartItem.thirdItem.excludedIngredients.join(", ")}
                        </p>
                      )}
                    </>
                  )}
                  {cartItem.fourthItem.name && (
                    <>
                      <h5>Czwarty produkt: {cartItem.fourthItem.name}</h5>
                      {cartItem.fourthItem.crust.length > 0 && (
                        <p>Ciasto: {cartItem.fourthItem.crust}</p>
                      )}

                      {cartItem.fourthItem.addedIngredients.length > 0 && (
                        <p>
                          Dodatki:{" "}
                          {cartItem.fourthItem.addedIngredients.join(", ")}
                        </p>
                      )}
                      {cartItem.fourthItem.excludedIngredients.length > 0 && (
                        <p>
                          Minus:{" "}
                          {cartItem.fourthItem.excludedIngredients.join(", ")}
                        </p>
                      )}
                    </>
                  )}

                </div>
                <div className="price">
                  {cartItem.quantity}x {cartItem.price.toFixed(2)}zł
                </div>
              </div>
            </div>
            {editable && (
              <div className="itemRight">
                {cartItem.quantity > 1 && <button onClick={()=>handleSubtractQuantity(cartItem)}>-</button>}
                <button onClick={()=>handleAddQuantity(cartItem)}>+</button>
                <DeleteForeverOutlinedIcon
                  className="cartDelete"
                  onClick={() =>
                    dispatch(
                      removeItem({
                        id: cartItem.id,
                        addedIngredients: cartItem.addedIngredients,
                        excludedIngredients: cartItem.excludedIngredients,
                        size: cartItem.size,
name: cartItem.name,
                        drink: cartItem.drink,
                        taste: cartItem.taste,
                        crust: cartItem.crust,
                        firstHalf: cartItem.firstHalf,
                        secondHalf: cartItem.secondHalf,
firstItem: cartItem.firstItem,
                        secondItem: cartItem.secondItem,
                        thirdItem: cartItem.thirdItem,
                        fourthItem: cartItem.fourthItem
                      })
                    )
                  }
                />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderItems;

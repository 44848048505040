import "./newVoucher.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewVoucher = ({ inputs, title }) => {
  const [info, setInfo] = useState({
    isPercentage: true,
    name: "",
    value: 0,
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
  });
  const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL, withCredentials: true})

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  console.log(info);

  const isValidDateRange = () => {
    const { startDate, endDate } = info;
    return new Date(endDate) >= new Date(startDate);
  };

  const handleClick = async (e) => {
    e.preventDefault();

 if (!isValidDateRange()) {
      console.log("Invalid date range");
      return;
    }

    const newVoucher = {
      ...info,
    };
    console.log(newVoucher);
    try {
      await axiosInstance.post("/vouchers", newVoucher);
      navigate("/vouchers/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                  />
                </div>
              ))}
<div className="formInput">
                <label>Data początkowa</label>
                <input
                  type="date"
                  id="startDate"
                  onChange={handleChange}
                  value={info.startDate}
                />
              </div>
              <div className="formInput">
                <label>Data końcowa</label>
                <input
                  type="date"
                  id="endDate"
                  onChange={handleChange}
                  value={info.endDate}
                />
              </div>
             
              <div className="formInput">
                <label>Rodzaj</label>
                <select id="isPercentage" onChange={handleChange}>
                  <option value={true}>Procentowy</option>
                  <option value={false}>Kwotowy</option>
                </select>
              </div>
              <button onClick={handleClick}>Wyślij</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewVoucher;

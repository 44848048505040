import React from "react";
import "./report.scss";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Report = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [email, setEmail] = useState();
  const [pin, setPin] = useState("");
  const [message, setMessage] = useState("");
  const [isPinCorrect, setIsPinCorrect] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleClick = async () => {
    setMessage("");
    const startDay = startDate.substr(8, 2);
    const startMonth = startDate.substr(5, 2);
    const startYear = startDate.substr(0, 4);
    const endDay = endDate.substr(8, 2);
    const endMonth = endDate.substr(5, 2);
    const endYear = endDate.substr(0, 4);
    let link = `${startDay}/${startMonth}/${startYear}/${endDay}/${endMonth}/${endYear}/${email}`;

    try {
      await axiosInstance.get(`/orders/report/${link}`);
      setMessage("Raport wygenerowano pomyślnie.");
    } catch (error) {
      console.log(error);
      setMessage("Wystąpił błąd: " + error);
    }
  };

  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const handlePinCorrect = () => {
    if (pin === process.env.REACT_APP_PIN) {
      setIsPinCorrect(true);
    } else {
      setIsPinCorrect(false);
    }
  };

  return (
    <div className="reportContainer">
      <div className="reportDetails">
        <div className="reportTop">
          <h1>WYGENERUJ RAPORT</h1>

          <Link to="/" style={{ textDecoration: "none" }}>
            <button className="closeButton">&times;</button>
          </Link>
        </div>
        <div className="reportCenter">
          <div className="pin">
            <input
              className="pinInput"
              type="password"
              placeholder="Wprowadź hasło"
              value={pin}
              onChange={handlePinChange}
            />
            <button className="pinBtn" onClick={handlePinCorrect}>
              ENTER
            </button>
          </div>
          {isPinCorrect && (
            <>
              <input
                type="email"
                placeholder="wprowadź adres email"
                value={email}
                onChange={handleEmail}
              />

              <div className="reportForm">
                <div className="formInput">
                  DATA POCZĄTKOWA:
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleStartDate}
                  />
                </div>
                <div className="formInput">
                  DATA KOŃCOWA:
                  <input type="date" value={endDate} onChange={handleEndDate} />
                </div>
                <button className="addBtn" onClick={handleClick}>
                  WYŚLIJ RAPORT
                </button>
              </div>
            </>
          )}
        </div>
        {message && (
          <div className="reportMessage">
            <p>{message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Report;

import "./editVoucher.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const EditVoucher = ({ inputs, title }) => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { data, loading, error } = useFetch(`/vouchers/find/${id}`);
  const [info, setInfo] = useState({});
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

const isValidDateRange = () => {
    const { startDate, endDate } = info;
    return new Date(endDate) >= new Date(startDate);
  };

  const handleClick = async (e) => {
    e.preventDefault();

 if (!isValidDateRange()) {
      console.log("Invalid date range");
      return;
    }

    console.log({ ...info });
    try {
      await axiosInstance.put(`/vouchers/${id}`, { ...info });
      navigate("/vouchers/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="edit">
      <Sidebar />
      <div className="editContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            {loading ? (
              "loading"
            ) : (
              <form>
                {inputs.map((input) => {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <input
                        onChange={handleChange}
                        type={input.type}
                        defaultValue={data[input.id]}
                        id={input.id}
                      />
                    </div>
                  );
                })}

<div className="formInput">
                  <label>Data początkowa</label>
                  <input
                    type="date"
                    id="startDate"
                    onChange={handleChange}
                    value={info.startDate}
                  />
                </div>
                <div className="formInput">
                  <label>Data końcowa</label>
                  <input
                    type="date"
                    id="endDate"
                    onChange={handleChange}
                    value={info.endDate}
                  />
                </div>

                <div className="formInput">
                  <label>Rodzaj</label>
                  <select
                    id="isPercentage"
                    onChange={handleChange}
                    value={info?.isPercentage}
                  >
                    <option value={true}>Procentowy</option>
                    <option value={false}>Kwotowy</option>
                  </select>
                </div>

                <button onClick={handleClick}>Wyślij</button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditVoucher;

import "./orderDetails.scss";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import HomeIcon from '@mui/icons-material/Home';

const OrderDetails = ({ item }) => {
  const handleMethod = (order) => {
    switch (order.paymentMethod) {
      case "cash":
        return "gotówka";
      case "online":
        return "online";
case "pyszne":
        return "pyszne";
      case "terminal":
        return "karta płatnicza";
    }
  };

  return (
    <>
      {item.comments && (
        <div className="orderComments">
          <h1>Uwagi do zamówienia:</h1>
          <span>{item.comments}</span>
        </div>
      )}
      {item.nip && (
        <div className="orderComments">
          <h1>NIP:</h1>
          <span>{item.nip}</span>
        </div>
      )}
      <div className="orderDetails">
        <div className="orderDetailsLeft">
          <h2>Dane osobowe:</h2>
          <span>
           <PersonIcon className="icon" /> {item.firstName} {item.lastName}
          </span>
          <span>
            <HomeIcon className="icon" />{item.street && (item.street+" "+item.homeNumber+", "+item.city) }
            {/* {item.street} {item.homeNumber}, {item.city} */}
          </span>
          <span><LocalPhoneIcon className="icon" /> {item.phone}</span>
        </div>
        <div className="orderDetailsRight">
          <h2>Szczegóły:</h2>
          <span><CreditCardIcon className="icon" /> {item.totalPrice?.toFixed(2)}zł</span>
          <span>
            <PriceCheckIcon className="icon" /> {item.paymentMethod && handleMethod(item)}
          </span>
          <span>
            <AccessTimeIcon className="icon" />
            {item.deliveryTime === "jak najszybciej"
                ? "ASAP"
                : item.deliveryTime}
          </span>
          <span><DeliveryDiningIcon className="icon" />{item.delivery === true ? "dostawa" : "odbiór osobisty" } </span>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;

import "./orderCard.scss";
import Modal from "../modal/Modal";

import { useState } from "react";
import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import axios from "axios";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import OrderItems from "../orderItems/OrderItems";
import CommentIcon from "@mui/icons-material/Comment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const OrderCard = ({ order, showProducts, listMode, fetch, index }) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  const [openModal, setOpenModal] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openMethod, setOpenMethod] = useState(false);
  const [openReciver, setOpenReciver] = useState(false);
  const [info, setInfo] = useState(order);
  const [time, setTime] = useState(order.deliveryTime);
  const { data } = useFetch(`/employees`);

useEffect(() => {
    setInfo(order);
    setTime(order.deliveryTime);
  }, [order]);

  const delivery = new Date();
  delivery.setHours(time.slice(0, 2));
  delivery.setMinutes(time.slice(3, 5));
  const now = new Date();
  const timeDiff = delivery.getTime() - now.getTime();
  const timeDiffMinutes = Math.round(timeDiff / (1000 * 60));

  const handleOrderStatus = async (status) => {
    try {
      const newOrder = {
        ...info,
        status: status,
      };
      setInfo(newOrder);
      await axiosInstance.put(`/orders/${order._id}`, newOrder);
      fetch();
      setOpenStatus(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentMethod = async (paymentMethod) => {
    try {
      const newOrder = {
        ...info,
        paymentMethod: paymentMethod,
      };
      setInfo(newOrder);
      await axiosInstance.put(`/orders/${order._id}`, newOrder);
           fetch();

      setOpenMethod(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentIsCompleted = async (paymentCompleted) => {
    try {
      const newOrder = {
        ...info,
        paymentCompleted: paymentCompleted,
      };
      setInfo(newOrder);
      await axiosInstance.put(`/orders/${order._id}`, newOrder);
      fetch();

      setOpenMethod(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentReciver = async (paymentReciver) => {
    try {
      const newOrder = {
        ...info,
        paymentReciver: paymentReciver._id,
      };
      setInfo(newOrder);
      await axiosInstance.put(`/orders/${order._id}`, newOrder);
      fetch();

      setOpenReciver(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card">
 {listMode ? (
        <>
 <div className={"cardList"+ (order.comments ? 'Bold' : 'Normal')+((order.paymentMethod==="online" && order.paymentCompleted === false) ? 'false' : '' )}>
            <span>
              <div
                className="left"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <p>{new Date(order.createdAt).toLocaleTimeString()}</p>
                <AccessTimeIcon className="icon" />
                {order.deliveryTime === "jak najszybciej" ? (
                  "ASAP"
                ) : (
                  <p className="alert">{order.deliveryTime.substring(0, 5)}</p>
                )}
                <span>
                  <CreditCardIcon className="icon" />
                  {order.totalPrice.toFixed(2)}
                  {order.paymentMethod === "terminal" ? "K" : "zł"}
                </span>
                <DeliveryDiningIcon className="icon" />
                {order.delivery ? (
                  <span>
                    {order.street} {order.homeNumber}, {order.city}
                  </span>
                ) : (
                  "Odbiór osobisty"
                )}
              </div>
              <div className="right">
                <div
                  className={"preparation"}
                  onClick={() => handleOrderStatus("preparation")}
                ></div>
                <div
                  className={"ready"}
                  onClick={() => handleOrderStatus("ready")}
                ></div>
                <div
                  className={"delivered"}
                  onClick={() => handleOrderStatus("delivered")}
                ></div>
                <div
                  className={"cancelled"}
                  onClick={() => handleOrderStatus("cancelled")}
                ></div>
              </div>
            </span>
          </div>
          {showProducts && (
            <div className="center">
              <OrderItems products={order.products} />
            </div>
          )}
        </>
      ) : (
        <>
      <div className={"cardContainer" + (order.comments ? 'Bold' : 'Normal')+((order.paymentMethod==="online" && order.paymentCompleted === false) ? 'false' : '' )}>
        {order.nip && (
          <span className="nip">
            <LocalAtmIcon className="icon" />
            NIP: {order.nip}
          </span>
        )}
        <div className="top">
          <div
            className="left"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {(order.status === "preparation" || order.status === "ready") &&
              timeDiffMinutes < 30 && <h2>ZOSTAŁO {timeDiffMinutes} MINUT!</h2>}
            {order._id}
            <h1>
              {order.firstName} {order.lastName}
            </h1>
            <p>{new Date(order.createdAt).toLocaleTimeString()}</p>
          </div>
          <div
            className={order.status}
            onClick={() => setOpenStatus(!openStatus)}
          ></div>
        </div>
        {openStatus && (
          <div className="statusChange">
            <div
              className={
                order.status === "preparation" ? "status active" : "status"
              }
              onClick={() => handleOrderStatus("preparation")}
            >
              kuchnia
            </div>
            <div
              className={order.status === "ready" ? "status active" : "status"}
              onClick={() => handleOrderStatus("ready")}
            >
              dostawa
            </div>
            <div
              className={
                order.status === "delivered" ? "status active" : "status"
              }
              onClick={() => handleOrderStatus("delivered")}
            >
              dostarczone
            </div>
            <div
              className={
                order.status === "cancelled" ? "status active" : "status"
              }
              onClick={() => handleOrderStatus("cancelled")}
            >
              odrzucone
            </div>
          </div>
        )}

        {showProducts && (
          <div className="center">
            <OrderItems products={order.products} />
          </div>
        )}
        <div className="bottom">
          <div className="left">
            <span>
              <CreditCardIcon className="icon" />
              {order.totalPrice.toFixed(2)}zł
            </span>

            <span>
              <AccessTimeIcon className="icon" />
              {order.deliveryTime === "jak najszybciej"
                ? "ASAP"
                : <p className="alert">{order.deliveryTime}</p>}
            </span>

            <span>
              <DeliveryDiningIcon className="icon" />
              {order.delivery ? (
                <span>
                  {order.street} {order.homeNumber}, {order.city}
                </span>
              ) : (
                "Odbiór osobisty"
              )}
            </span>
          </div>
          <div className="right">
            <span>
              <LocalPhoneIcon className="icon" />
              <a href={`tel:${order.phone}`}>{order.phone}</a>
            </span>
            <span>
              <PriceCheckIcon
                className="icon iconClick"
                onClick={() => setOpenMethod(!openMethod)}
              />
              {order.paymentMethod === "cash"
                ? "gotówka"
                : order.paymentMethod === "terminal"
                ? "terminal"
: order.paymentMethod === "pyszne" 
                    ? "pyszne" 
                : "online"}
              {order.paymentCompleted === true && (
                <CheckBoxIcon className="iconGreen" />
              )}
              {order.paymentCompleted === false && (
                <ReportGmailerrorredIcon className="iconRed" />
              )}
            </span>
            <span>
              <PersonIcon
                className="icon iconClick"
                onClick={() => setOpenReciver(!openReciver)}
              />
              {order.paymentReciver && (
                <>
                  {data.map((employee) => {
                    return (
                      employee._id === order.paymentReciver && (
                        <span key={employee._id}>
                          {employee.firstName} {employee.lastName}
                        </span>
                      )
                    );
                  })}
                </>
              )}
            </span>
          </div>
        </div>
        {order.comments && (
          <span className="comments">
            <CommentIcon className="icon" />
            {order.comments}
          </span>
        )}
        {openMethod && (
          <>
            <div className="statusChange">
              <div
                className={
                  order.paymentMethod === "cash" ? "status active" : "status"
                }
                onClick={() => handlePaymentMethod("cash")}
              >
                gotówka
              </div>
              <div
                className={
                  order.paymentMethod === "terminal"
                    ? "status active"
                    : "status"
                }
                onClick={() => handlePaymentMethod("terminal")}
              >
                karta
              </div>
              <div
                className={
                  order.paymentMethod === "online" ? "status active" : "status"
                }
                onClick={() => handlePaymentMethod("online")}
              >
                online
              </div>
<div
                    className={
                      order.paymentMethod === "pyszne"
                        ? "status active"
                        : "status"
                    }
                    onClick={() => handlePaymentMethod("pyszne")}
                  >
                    pyszne
                  </div>
            </div>
            <div className="statusChange">
              <div
                className={
                  order.paymentCompleted === true ? "status active" : "status"
                }
                onClick={() => handlePaymentIsCompleted(true)}
              >
                zapłacone
              </div>
              <div
                className={
                  order.paymentCompleted === false ? "status active" : "status"
                }
                onClick={() => handlePaymentIsCompleted(false)}
              >
                do zapłaty
              </div>
            </div>
          </>
        )}
        {openReciver && (
          <div className="statusChange">
            {data.map((item) => {
              return (
                <div
                  onClick={() => handlePaymentReciver(item)}
                  className="status"
                  key={item._id}
                >
                  {item.firstName}
                </div>
              );
            })}
          </div>
        )}
      </div> </>
      )}
      {openModal && (
        <Modal order={order} closeModal={setOpenModal} key={order._id} fetch={fetch} />
      )}
    </div>
  );
};

export default OrderCard;

import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  selectedDriver: null,
};

export const DriverContext = createContext(INITIAL_STATE);

const DriverReducer = (state, action) => {
  switch (action.type) {
    case "SELECT_DRIVER": {
      return {
        selectedDriver: action.selectedDriver,
      };
    }
    default:
      return state;
  }
};

export const DriverContextProvider = ({ children }) => {
  const [state, dispatchDriver] = useReducer(DriverReducer, INITIAL_STATE);

  return (
    <DriverContext.Provider value={{ selectedDriver: state.selectedDriver, dispatchDriver }}>
      {children}
    </DriverContext.Provider>
  );
};
